<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('course.title2')"
        left-arrow
        @click-left="onClickLeft"
      />
    </div>
    <div class="maincontent">
      <van-row class="agreement">
        <h1>{{ lang === 'zh' ? detail.title : detail[`title_${lang}`] }}</h1>
        <p class="text"></p>
        
        <p v-html="lang === 'zh' ? detail.content : detail[`content_${lang}`]"></p>
      </van-row>
    </div>

    <van-popup 
        :close-on-click-overlay="false"
        v-model="showDialog" style="background-color:transparent; overflow:hidden;"
    >
        <div class="loadingWrap">
            <van-loading v-if="showDialog" type="spinner" />
        </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lang: localStorage.getItem('lang') || 'zh',
      detail: {},
      showDialog: false,
    }
  },
  created() {
    this.getdetail()
  },
  methods: {
    async getdetail() {
      this.showDialog = true;
      const { data } = await this.$http.get(
        '/home/home/coursedetail/id/' + this.$route.params.id
      )
      if (data) {
        if (data.code === 200) {
          this.detail = data.data
        }
      }
      this.showDialog = false;
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
.maincontent {
  padding-top: 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  height: 100vh;
  .agreement {
    width: 100%;
    color: #000;
    overflow: scroll;
    h1 {
      font-size: 1.75rem;
      color: #2c3e50;
      text-align: left;
      margin: 2.66667rem 0.66667rem 0.53333rem;
      margin-top: 0px;
      font-weight: 500;
      word-break: break-all;
      line-height: 1.5;
      // padding-top: 1.33333rem;
      // border-top: 0.02667rem solid #a3a3a3;
    }
    p {
      text-indent: 1.54667rem;
      text-align: left;
      margin: 0.66667rem;
      font-size: 0.93333rem;
      line-height: 1.3rem;
    }
  }
}
</style>
